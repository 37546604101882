import {logger} from 'tizra'
import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

const log = logger('HeadlineBlock')

export const HeadlineBlock = B.blockWithInfo(
  {...meta, Admin, ssrEnabled: false},
  ({config}) => {
    const bp = 'md'
    const twoColumnHeadline = B.useUp(bp)

    const leftProps: B.BrowseProps = {
      ...B.useBrowseProps(config.left),
      twoColumnHeadline,
    }
    const left = B.useBrowse(leftProps).gallery

    const rightProps: B.BrowseProps = {
      ...B.useBrowseProps(config.right),
      twoColumnHeadline,
    }
    const right = (
      <div style={{position: 'relative'}}>
        {B.useBrowse(rightProps).gallery}
      </div>
    )

    return (
      <B.Section>
        <B.Grid
          gridTemplateColumns={{_: '1fr', [bp]: '2fr 1fr'}}
          // Only applies when stacked on mobile. Matches the stack spacing for
          // titles in BrowseGallery/index.tsx
          rowGap="xl"
        >
          {left}
          {right}
        </B.Grid>
      </B.Section>
    )
  },
)
