import * as A from '../admin'
import {AttachmentsBlockConfig} from './meta'

export const Admin: A.Admin<AttachmentsBlockConfig> = ({values}) => {
  const sourceOptions = A.useSourceOptions()
  return (
    <>
      <A.Group title="Content">
        <A.Input label="Heading" name="heading" />
        <A.Unchecklist
          label="Included sources"
          name="excludedSources"
          options={sourceOptions}
        />
        <A.YesNo label="Filter by user licenses?" name="filterByUserLicenses" />
      </A.Group>
      <A.Group title="Presentation">
        <A.YesNo label="Show icons?" name="iconsEnabled" />
        {values.iconsEnabled && (
          <A.Radio
            label="Icon size"
            name="iconSize"
            options={A.options({
              auto: 'Auto based on number of attachments',
              small: 'Small',
              medium: 'Medium',
              large: 'Large',
            })}
          />
        )}
      </A.Group>
    </>
  )
}
