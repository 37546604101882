import {logger} from 'tizra'
import * as B from '../block'
import {Admin, GlobalAdmin} from './admin'
import {meta} from './meta'

const log = logger('SignInBlock')

export const SignInBlock = B.blockWithInfo(
  {...meta, Admin, GlobalAdmin},
  ({config: {authAgent, ...config}}) => {
    const authConfig = B.useAuthConfig({authAgent})

    // Default successUrl here because we're definitely in block instead of
    // modal. If there is a successUrl in the query string, then the server will
    // pass it in block context, otherwise assume we should return to the home
    // page after a successful operation.
    const successUrl = B.useSuccessUrl() || '/'

    return (
      <B.Section>
        <B.Auth
          {...authConfig}
          {...config}
          modal={false}
          successUrl={successUrl}
          view="signin"
        />
      </B.Section>
    )
  },
)
