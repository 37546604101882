import {PropDef} from 'tizra'
import * as A from '../admin'
import {DetailsBlockConfig} from './meta'

// This is broken out of the usual A.usePropOptions so that the block can re-use
// it.
export const propertyFilter = (def: PropDef) =>
  def.isUserVisible && A.DETAIL_TYPES.includes(def.type)

export const Admin: A.Admin<DetailsBlockConfig> = () => {
  const propOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    filter: propertyFilter,
  })

  return (
    <>
      <A.Group title="Content">
        <A.Unchecklist
          label="Included properties"
          name="excludes"
          options={propOptions}
        />
      </A.Group>
      <A.Group title="Presentation">
        <A.Input label="Heading" name="heading" />
      </A.Group>
    </>
  )
}
