import {
  SearchConfigWithSparseFields,
  SparseConfig,
  SparseField,
} from 'quickstart/lib/search/config'
import * as R from 'rambdax'
import {useMemo} from 'react'
import {deepMerge, logger, truthy, tryJson} from 'tizra'
import {useGlobalBlockConfig} from './useGlobalBlockConfig'
import {UseSearchConfigOptions, useSearchConfig} from './useSearchConfig'
import {useWithinField} from './useWithinField'

const log = logger('useGlobalSearchConfig')

const WITHIN_PARAM_NAME = 'within'

const PREFIX = 'searchParam-'

export const defaultTips = `
**Required terms**
Use a plus sign before each required term.

_Example_
+automobile +windshield +glass

**Excluded terms**
Use a minus sign before each excluded term.

_Example_
+rotary +kiln –cement

**Wildcards**
Use an asterisk anywhere after the first letter in the term.

_Example_
auto* will return results for automobile, automatic, autonomous

**Exact phrases**
Enclose in double quotes.

_Example_
"basic refractories"
`.trim()

export const useGlobalSearchConfig = (
  overrideConfig?: SparseConfig | null,
  options?: UseSearchConfigOptions,
) => {
  const searchBlockGlobalConfig = useGlobalBlockConfig('search')

  const customSearchConfig = useMemo(
    () =>
      tryJson<any>(searchBlockGlobalConfig.customSearchConfigJson, e =>
        log.error('failed to parse customSearchConfigJson', e),
      ),
    [searchBlockGlobalConfig.customSearchConfigJson],
  )

  const within = useWithinField({
    config: searchBlockGlobalConfig.fields.within,
    name: WITHIN_PARAM_NAME,
    urlPrefix: PREFIX,
  })

  const baseConfig: Partial<SearchConfigWithSparseFields> = {
    metaTypes: {
      metadata: searchBlockGlobalConfig.metadataMetaTypes,
    },
    depths: (
      [
        !!searchBlockGlobalConfig.metadataMetaTypes.length && 'metadata',
        !!searchBlockGlobalConfig.tocDepth && 'toc',
        !!searchBlockGlobalConfig.fulltextDepth && 'fulltext',
      ] as const
    ).filter(truthy),
    fields: {
      terms: {
        tips: searchBlockGlobalConfig.fields.terms.customTips || defaultTips,
      },
      // ALWAYS include within, even if not enabled as a facet. That way the
      // Search Within block works, and the user can still remove the
      // constraint with the clickable filter tag. If not enabled as a facet,
      // it will be excluded from order below.
      [WITHIN_PARAM_NAME]: within as SparseField,
    },
    order: ['terms', 'within'],
  }

  const mergedConfig = R.piped(
    baseConfig,
    x => deepMerge(x)(customSearchConfig),
    x => deepMerge(x)(overrideConfig),
  )

  const searchConfig = useSearchConfig(mergedConfig, options)
  return searchConfig
}
