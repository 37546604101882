import {logger, MetaObject, semiSplit} from 'tizra'
import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

const log = logger('SubnavBlock')

export const SubnavBlock = B.blockWithInfo(
  {...meta, Admin, dividers: false},
  ({config: {heading, match}}) => {
    const {tizraId} = B.useBlockContext()

    const {data} = B.useApi.search({
      any: semiSplit(match.any),
      all: ['metaType:SubjectCollection'].concat(semiSplit(match.all)),
      excluded: ['metaType:VirtualCollection;VirtualCollectionInstance'].concat(
        semiSplit(match.excluded),
      ),
    })

    return (
      <B.Section variant="abutting">
        <B.SideNav heading={heading}>
          {data?.result.map((metaObj, i) => (
            <B.Text variant="subnav" key={i}>
              <B.MetaLink
                metaObj={metaObj}
                variant={
                  (metaObj as MetaObject).tizraId === tizraId ?
                    'sidebar-current'
                  : 'sidebar'
                }
              />
            </B.Text>
          ))}
        </B.SideNav>
      </B.Section>
    )
  },
)
