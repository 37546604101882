import {useAutoComplete, useMarkdown, UseSearchReturn} from 'quickstart/hooks'
import {AutoCompleteResult} from 'quickstart/lib/search/autocomplete'
import {Combobox, ComboboxProps} from './Combobox'
import {Button} from '../controls/Button'
import {Icon} from '../content/Icon'
import {Modal} from '../layout/Modal'
import {Html} from '../content/Html'

interface TermsInputProps
  extends Omit<
    ComboboxProps<AutoCompleteResult>,
    'name' | 'itemToKey' | 'itemToString' | 'renderItem' | 'search'
  > {
  search: UseSearchReturn
  name?: string
  tips?: string
}

export const TermsInput = ({
  search: {config},
  name = 'terms',
  minChars = 3,
  placeholder = 'Search…',
  submitOnSelect = true,
  tips,
  ...props
}: TermsInputProps) => {
  const autocomplete = useAutoComplete(config)

  const modal = Modal.useStore()
  const tipsHtml = useMarkdown(tips) || ''
  const hint =
    !tips ? undefined : (
      <div>
        <Button onClick={modal.show} variant="stealth" color="linkColor">
          <Icon icon="info" />
          See tips
        </Button>{' '}
        for using special search characters
      </div>
    )

  return (
    <>
      <Combobox<AutoCompleteResult>
        name={name}
        itemToString={x => x?.full ?? ''}
        renderItem={item =>
          item && (
            <span>
              {item.before}
              <strong>{item.suggestion}</strong>
              {item.after}
            </span>
          )
        }
        search={autocomplete}
        hint={hint}
        minChars={minChars}
        placeholder={placeholder}
        submitOnSelect={submitOnSelect}
        {...props}
      />
      <Modal aria-label="search tips" store={modal}>
        <Modal.Title>Search Tips</Modal.Title>
        <Modal.Content>
          <Html html={tipsHtml} variant="raw" />
        </Modal.Content>
      </Modal>
    </>
  )
}
