import {Modal} from 'quickstart/components/layout/Modal'
import {Auth, AuthView} from 'quickstart/components/tizra/Auth'
import {useAuthConfig} from 'quickstart/hooks'
import {useCallback, useState} from 'react'

export type SignInModalView = Exclude<AuthView, 'changePassword'>

/**
 * Augment useModalStore to accept defaultView, and return an augmented state
 */
const useSignInModalProps = ({
  defaultView = 'signin',
  ...options
}: Parameters<typeof Modal.useStore>[0] & {
  defaultView?: SignInModalView
} = {}) => {
  const store = Modal.useStore(options)
  const [view, setView] = useState<SignInModalView>(defaultView)

  // Modified show() accepts a view
  const show = useCallback<(v?: SignInModalView) => void>(
    v => {
      setView((typeof v === 'string' && v) || 'signin')
      store.show()
    },
    [store],
  )

  // Augmented state includes current view
  return {store: {...store, show}, view}
}

interface SignInModalProps {
  authConfig: ReturnType<typeof useAuthConfig>
  store: ReturnType<typeof Modal.useStore>
  view: SignInModalView
}

const _SignInModal = ({authConfig, store, view}: SignInModalProps) => {
  const isOpen = store.useState('open')
  return (
    <Modal key="modal" size="sm" aria-label="sign in" store={store}>
      <Auth
        {...authConfig}
        autoFocus={
          // By passing open state here, the effect in Input will fire to focus
          // when the modal appears, otherwise it doesn't work.
          isOpen
        }
        modal={true}
        show={store.show}
        view={view}
      />
    </Modal>
  )
}

export const SignInModal = Object.assign(_SignInModal, {
  useProps: useSignInModalProps,
})
