import * as cookie from 'cookie'
import * as H from 'quickstart/hacks'
import {BlockContext} from 'quickstart/types'
import {TREE, dom, logger, storage} from 'tizra'

/**
 * Bust the persistent cache when LoginStatus changes.
 * https://github.com/Tizra/cubchicken/pull/724
 */
export const getCookieBuster = ({
  cookie: s = document.cookie,
  now = Date.now(),
} = {}) => {
  const loginStatus = cookie.parse(s).LoginStatus ?? ''

  const lastCookieKey = `${TREE}cookieLast`
  const lastCookie = storage.get(lastCookieKey) || ''
  storage.put(lastCookieKey, loginStatus)

  // We don't actually care what's in the LoginStatus cookie, only that it
  // changed. We use a timestamp to avoid re-accessing an older cache if
  // LoginStatus reverts to a previous value.
  const cookieBusterKey = `${TREE}cookieBuster`
  const cookieBuster =
    lastCookie !== loginStatus ?
      storage.put(cookieBusterKey, `${now}`)
    : storage.get(cookieBusterKey) ?? ''

  return cookieBuster
}

/**
 * Bust the persistent cache when there's a session error.
 */
export const getErrorBuster = ({
  context,
  log,
  now = Date.now(),
}: {
  context: BlockContext
  log: ReturnType<typeof logger>
  now?: number
}) => {
  const isError = !!context.sessionErrors.length
  const errorBusterKey = `${TREE}errorBuster`
  const errorBuster =
    isError ?
      (log.log('detected session error, busting cache'),
      storage.put(errorBusterKey, `${now}`))
    : storage.get(errorBusterKey) ?? ''
  return errorBuster
}

/**
 * Bust the persistent cache on reload, either user-initiated or
 * programmatic. This triggers on simple reload, although it might still get
 * data that was cached at the HTTP layer if you don't shift-reload for
 * a hard refresh.
 */
export const getReloadBuster = ({
  hacks,
  log,
  isReload = dom.navigationType() === 'reload',
  now = Date.now(),
}: {
  hacks: H.Hacks
  log: ReturnType<typeof logger>
  isReload?: boolean
  now?: number
}) => {
  const reloadBusterKey = `${TREE}reloadBuster`
  const reloadBuster =
    isReload ?
      (log.log(`detected reload, reloadBehavior=${hacks.reloadBehavior}`),
      hacks.reloadBehavior === 'bust' ?
        storage.put(reloadBusterKey, `${now}`)
      : '')
    : storage.get(reloadBusterKey) ?? ''
  return {isReload, reloadBuster}
}

/**
 * Bust persistent cache when clock skew delta exceeds tolerance.
 * https://github.com/Tizra/cubchicken/issues/859
 */
export const getSkewBuster = ({
  context,
  hacks,
  log,
  now = Date.now(),
}: {
  context: BlockContext
  hacks: H.Hacks
  log: ReturnType<typeof logger>
  now?: number
}) => {
  let renderedAt: number
  try {
    renderedAt = new Date(context.renderedAt).getTime()
  } catch (e) {
    log.error('could not parse context.renderedAt for skewBuster', context, e)
    return ''
  }

  // Calculate apparent skew between server rendering and Evergreen init.
  const skew = Math.abs(now - renderedAt)

  // Handle persistent skew (wrong time on client) by tracking last skew.
  const lastSkewKey = `${TREE}skewLast`
  const lastSkew = parseInt(storage.get(lastSkewKey) || '0')
  storage.put(lastSkewKey, `${skew}`)

  // Calculate skew delta: current minus last. If we have no last skew,
  // then use apparent skew.
  const skewDelta = lastSkew ? Math.abs(skew - lastSkew) : skew

  // If delta exceeds tolerance, then update buster.
  const skewBusterKey = `${TREE}skewBuster`
  const skewBuster =
    skewDelta > hacks.clockSkewTolerance * 1000 ?
      (log.warn(`clock skew detected (${skewDelta}ms), busting cache`),
      storage.put(skewBusterKey, now.toString()))
    : storage.get(skewBusterKey) ?? ''
  return skewBuster
}
