import * as A from '../admin'
import {TocBlockConfig} from './meta'

export const Admin: A.Admin<TocBlockConfig> = () => {
  return (
    <>
      <A.Group title="Presentation">
        <A.Input label="Heading" name="heading" />
        <A.Radio
          label="Style"
          name="style"
          options={A.options({
            lined: 'Lined',
            bars: 'Bars',
            plain: 'Plain',
          })}
        />
        <A.YesNo label="Show page numbers?" name="showPageNums" />
        <A.YesNo label="Label free content?" name="labelFreeContent" />
      </A.Group>
    </>
  )
}
