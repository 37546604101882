import {AuthAgent} from 'quickstart/types'
import {logger} from 'tizra'
import {useGlobalBlockConfig} from './useGlobalBlockConfig'

const log = logger('useAuthConfig')

interface UseAuthConfigProps {
  authAgent?: AuthAgent | ''
}

export const useAuthConfig = (props: UseAuthConfigProps = {}) => {
  const {logoLink, logoUrl} = useGlobalBlockConfig('theme')
  const authConfig = {
    ...useGlobalBlockConfig('signin'),
    logoLink,
    logoUrl,
  }
  log.debug?.('global authConfig is:', authConfig)
  if (props.authAgent) {
    log.debug?.('received authAgent in props:', props.authAgent)
    authConfig.authAgent = props.authAgent
  }
  if (authConfig.authAgent !== 'tizra') {
    log.debug?.(
      `authAgent is ${authConfig.authAgent}, disabling passwordReset and registration`,
    )
    authConfig.passwordReset.enabled = false
    authConfig.registration.enabled = false
  }
  log.debug?.('authConfig is', authConfig)
  return authConfig
}
