import {
  SlotConfig,
  defaultSlots,
} from 'quickstart/components/tizra/MetaTile/common'
import * as R from 'rambdax'
import {useCallback, useMemo} from 'react'
import {
  MetaObject,
  SearchResult,
  adminTagged,
  isCollectionMetaType,
  isExcerpt,
  isExcerptMetaType,
} from 'tizra'
import * as B from '../block'
import {BookshelfControls} from './BookshelfControls'
import {Admin, GlobalAdmin} from './admin'
import {BookshelfBlockConfig, BookshelfBlockGlobalConfig, meta} from './meta'

interface TizraWindow extends Window {
  tizra: any
}

declare let window: TizraWindow

const DEFAULT_PAGE = 100

export const BookshelfBlock = B.blockWithInfo(
  {...meta, Admin, GlobalAdmin},
  ({config}: {config: BookshelfBlockConfig}) => {
    const {title} = useBookshelfConfig()
    const userData = B.useUserData()

    return (
      <B.Section heading={title}>
        {
          userData ?
            // The Bookshelf component is defined below.
            <Bookshelf config={config} />
          : userData === false ?
            <B.Text>
              Please{' '}
              <B.Link onClick={() => window.tizra.login()}>sign in</B.Link> to
              see your personalized bookshelf.
            </B.Text>
          : null // waiting for userData
        }
      </B.Section>
    )
  },
)

export function useBookshelfConfig(): BookshelfBlockGlobalConfig {
  const bookshelf = B.useGlobalBlockConfig(meta.name)
  const userData = B.useUserData()
  const userLicensesUrl = userData && `/${userData.urlId}/~userLicenses`
  return {
    ...bookshelf,
    title: bookshelf.title || 'My Bookshelf',
    url: bookshelf.url || userLicensesUrl || '',
  }
}

function useBookshelfSort({config: {sort}}: {config: BookshelfBlockConfig}) {
  return useMemo(() => {
    // Drop invalid entries from config.sort.props (empty name or label).
    // If there's nothing left, sort on Title.
    const filteredSortProps = sort.props.filter(x => x.name && x.label)
    const validSortProps =
      filteredSortProps.length ? filteredSortProps : (
        [
          {
            name: 'Title',
            defaultDirection: 'ascending' as const,
            label: 'Title',
          },
        ]
      )

    const sortOptions = validSortProps.flatMap(({name}, i) => [
      {value: `${i}-${name}-ascending`},
      {value: `${i}-${name}-descending`},
    ])

    // The sorting object describes to search.js how to apply the sort value. It's
    // a map from value (without -ascending or -descending) back to prop name.
    const sorting = validSortProps.reduce(
      (obj, {name}, i) => {
        obj[`${i}-${name}`] = name
        return obj
      },
      {} as {[key: string]: string},
    )

    return {validSortProps, sortOptions, sorting}
  }, [sort])
}

function useBookshelfSearch({
  config: {metaTypes, requiredAdminTags, excludedAdminTags},
  sortOptions,
  sorting,
}: {
  config: BookshelfBlockConfig
  sortOptions: Array<{value: string}>
  sorting: {[k: string]: string}
}): B.UseSearchReturn<MetaObject> {
  const searchConfig = B.useSearchConfig({
    mode: 'browse',
    metaTypes: {metadata: metaTypes},
    fields: {
      depth: {defaultValue: 'metadata'},
      filterLicenses: {defaultValue: true},
      page: {defaultValue: DEFAULT_PAGE},
      sort: {defaultValue: sortOptions[0].value, options: sortOptions},
    },
    pre: {
      all: adminTagged(requiredAdminTags),
      excluded: adminTagged(excludedAdminTags),
    },
    sorting,
  })

  const searched = B.useSearch<MetaObject>(searchConfig)

  const showingCollections = metaTypes.some(isCollectionMetaType)
  const showingExcerpts = metaTypes.some(isExcerptMetaType)
  const licensedIds = B.useLicensedIds({
    enabled: showingCollections || showingExcerpts,
  })

  const isDisplayableContent = useCallback(
    (x: SearchResult<MetaObject>) => {
      // If we are configured to show collections, then exclude collection
      // contents that aren't licensed directly.
      const isNotContentLicensedByCollection = (x: SearchResult<MetaObject>) =>
        !showingCollections || licensedIds?.has(x.tizraId)
      // If we are configured to show excerpts, then exclude any that aren't
      // licensed directly.
      const isNotExcerptLicensedByBook = (x: SearchResult<MetaObject>) =>
        !showingExcerpts ||
        !isExcerpt(x) ||
        licensedIds?.has(x.tizraId) ||
        !x.parent ||
        !licensedIds?.has(x.parent.tizraId)
      return (
        isNotContentLicensedByCollection(x) && isNotExcerptLicensedByBook(x)
      )
    },
    [licensedIds, showingCollections, showingExcerpts],
  )

  const lastResults = useMemo<typeof searched.lastResults>(
    () =>
      searched.lastResults && searched.lastResults.filter(isDisplayableContent),
    [searched.lastResults, isDisplayableContent],
  )

  const results = useMemo<typeof searched.results>(
    () => searched.results && searched.results.filter(isDisplayableContent),
    [searched.results, isDisplayableContent],
  )

  return {...searched, lastResults, results}
}

function Bookshelf({
  config,
  config: {display},
}: {
  config: BookshelfBlockConfig
}) {
  const redemptionConfig = B.useGlobalBlockConfig('redemption')
  const {validSortProps, sortOptions, sorting} = useBookshelfSort({config})
  const {
    search,
    search: {isFetching, lastResults},
  } = {search: useBookshelfSearch({config, sortOptions, sorting})}

  // Compatibility shim for Browse with tiles, since the Bookshelf admin still
  // has images/covers.
  const slots = R.piped(
    defaultSlots,
    R.map((slotConfig: SlotConfig, slotName: string) => ({
      ...slotConfig,
      prop: '',
      enabled:
        slotName === 'cover' ||
        slotName === 'link' ||
        (display === 'covers' && slotName === 'title'),
    })),
  )

  return (
    <>
      <BookshelfControls
        config={config}
        redemptionConfig={redemptionConfig}
        search={search}
        sortProps={validSortProps}
      />
      {lastResults?.length ?
        <B.BrowseGallery
          display="tiles"
          slots={slots}
          imageCropping="full"
          search={search}
        />
      : isFetching ?
        null
      : <B.Text>
          You don't have anything on your personalized bookshelf yet.
        </B.Text>
      }
    </>
  )
}
