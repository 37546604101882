import {BrowseGallery, Link, MetaLink} from 'quickstart/components'
import {
  useBlockContext,
  useMetaObj,
  useSearch,
  useSearchConfig,
  useUrlParams,
  useTypeDefs,
} from 'quickstart/hooks'
import {
  SearchConfigWithSparseFields,
  SparseConfig,
} from 'quickstart/lib/search/config'
import {Opt, notNullish} from 'quickstart/types'
import {useMemo} from 'react'
import {
  GenericMetaObject,
  MetaObject,
  SearchType,
  adminTagged,
  deepMerge,
  isCollection,
  logger,
  meta,
  tryJson,
} from 'tizra'
import {BrowseFilters} from './BrowseFilters'
import {BrowseSpec} from './admin'

const log = logger('Browse')

const DEFAULT_PAGE = 100

export interface BrowseProps
  extends Partial<
    Omit<BrowseSpec, 'heading' | 'maxItems' | 'customBrowseConfigJson'>
  > {
  maxItems?: number
  customBrowseConfig?: SparseConfig
  twoColumnHeadline?: boolean
}

/**
 * Convert from BrowseSpec to BrowseProps.
 */
export const useBrowseProps = ({
  display,
  customBrowseConfigJson,
  filterCollectionId,
  filterPropPrefix,
  heading,
  maxItems: _maxItems,
  moreLink,
  ...config
}: Opt<BrowseSpec, 'heading'>): BrowseProps => {
  const context = useBlockContext()
  const onCollectionHome = context.metaType?.includes('Collection')
  let maxItems = parseInt(_maxItems) || 0

  if (!filterCollectionId && onCollectionHome) {
    filterCollectionId = context.tizraId
  } else if (filterCollectionId === '_all') {
    filterCollectionId = ''
  }

  if (onCollectionHome && filterCollectionId === context.tizraId) {
    // useBrowse doesn't check onCollectionHome, so we override moreLink.show
    // here.
    moreLink = {...moreLink, show: false}
  } else if (display !== 'tiles' && display !== 'cards') {
    // This knowledge would be better in a per-display static config but we
    // don't have that.
    moreLink = {...moreLink, show: false}
  }

  const customBrowseConfig = useMemo(
    () =>
      tryJson<any>(customBrowseConfigJson, e =>
        log.error('failed to parse customBrowseConfigJson', e),
      ),
    [customBrowseConfigJson],
  )

  return {
    ...config,
    display,
    customBrowseConfig,
    filterCollectionId,
    filterPropPrefix: filterPropPrefix || 'Browse',
    maxItems,
    moreLink,
  }
}

const collectionFilters = ({
  collectionObj,
  filterPropPrefix,
  typeDef,
}: {
  collectionObj: GenericMetaObject
  filterPropPrefix: string
  typeDef?: SearchType
}) =>
  [1, 2]
    .map(i => {
      const propValue = meta.string(
        collectionObj,
        `${filterPropPrefix}Filter${i}`,
      )
      if (propValue) {
        const prop = propValue.replace(/^-/, '')
        const reverse = propValue.startsWith('-')
        const allOptionLabel =
          meta.string(collectionObj, `${filterPropPrefix}Any${i}`) ||
          meta.any(
            typeDef?.propDefsIncludingSubtypes?.[prop]?.displayName || prop,
          )
        return {prop, reverse, allOptionLabel}
      }
      return null
    })
    .filter(notNullish)

const useCollectionObj = (tizraId: string) => {
  const metaObj = useMetaObj({
    tizraId,
    options: {
      // https://github.com/Tizra/evergreen/issues/427#issuecomment-1976838934
      alertOnError: e => e.status !== 404,
    },
  })
  if (isCollection(metaObj)) {
    return metaObj
  }
}

export const useBrowse = ({
  metaTypes = ['AdminTagged'],
  filterCollectionId = '',
  requiredAdminTags = '',
  excludedAdminTags = '',
  maxItems = 0,
  moreLink = {show: false, conditional: false, customText: '', customUrl: ''},
  sortDirection = 'ascending',
  sortProp = 'Title',
  display = 'cards',
  filterMode = 'auto',
  filterPropPrefix = 'Browse',
  filters = [],
  customBrowseConfig,
  size,
  background,
  foreground,
  linkColor,
  imageCropping,
  imageFocus,
  slots,
  twoColumnHeadline,
}: BrowseProps) => {
  const collectionObj = useCollectionObj(filterCollectionId)

  // If there's more than one meta-type configured for browse, then we use
  // AdminTagged to decode the fields, and just hope that the meta-types don't
  // have conflicting field definitions.
  const filterFieldMetaType =
    metaTypes.length === 1 ? metaTypes[0] : 'AdminTagged'

  // ...but we still request search-types for all the configured meta-types,
  // since they will be needed to display the output. If we don't do this here,
  // then we end up making an individual search-types API call for each
  // meta-type that appears in the browse gallery.
  const typeDefs = useTypeDefs([filterFieldMetaType, ...metaTypes])

  const ffs =
    filterMode === 'custom' ? configFilterFields(filters)
    : filterMode === 'auto' && collectionObj ?
      configFilterFields(
        collectionFilters({
          collectionObj,
          filterPropPrefix,
          typeDef: typeDefs?.[filterFieldMetaType],
        }),
      )
    : []

  // To preserve compatibility with existing Browse Block behavior, maxItems
  // only applies when there are no filters.
  maxItems = ffs.length ? 0 : maxItems

  const browseConfig: Partial<SearchConfigWithSparseFields> = {
    mode: 'browse',
    metaTypes: {metadata: metaTypes},
    pre: {
      filterCollectionId,
      all: adminTagged(requiredAdminTags),
      excluded: [
        // Tag-defined collections include special meta-types that inherit
        // from the parent: PageRange, PdfPage and maybe toc-entry. Rarely
        // does anybody want PdfPage, so we exclude it here by default,
        // unless explicitly requested.
        ...(metaTypes.includes('PageRange') ? [] : ['metaType:PdfPage']),
        ...adminTagged(excludedAdminTags),
      ],
    },
    fields: {
      depth: {defaultValue: 'metadata'},
      page: {
        defaultValue: Math.min(DEFAULT_PAGE, maxItems || DEFAULT_PAGE),
      },
      sort: {defaultValue: `explicit-${sortDirection}`},
      ...Object.fromEntries(ffs),
    },
    order: ffs.map(([k]) => k),
    sorting: {explicit: sortProp},
  }

  const searchConfig = useSearchConfig(
    customBrowseConfig ?
      deepMerge(browseConfig)(customBrowseConfig)
    : browseConfig,
  )

  const {urlParams, setUrlParams} = useUrlParams({
    config: searchConfig.config,
    prefix: 'browseParam-',
  })

  const search = useSearch<MetaObject>(searchConfig, {
    urlParams,
    setUrlParams,
  })

  return {
    filters: !ffs.length ? null : <BrowseFilters search={search} />,
    gallery:
      !search.lastResults ? null : (
        <BrowseGallery
          display={display}
          maxItems={maxItems}
          search={search}
          size={size}
          slots={slots}
          background={background}
          foreground={foreground}
          linkColor={linkColor}
          imageCropping={imageCropping}
          imageFocus={imageFocus}
          twoColumnHeadline={twoColumnHeadline}
        />
      ),
    footer:
      (
        !moreLink.show ||
        !maxItems ||
        !search.lastResults ||
        !search.size ||
        // check if we've already seen the entire collection
        (moreLink.conditional && search.size <= maxItems) ||
        // check if there's more that will infinite load
        (search.lastResults.length < search.size &&
          search.lastResults.length < maxItems)
      ) ?
        null
      : <div>
          {moreLink.customUrl ?
            <Link href={moreLink.customUrl} variant="ui">
              {moreLink.customText || 'View all'}
            </Link>
          : <MetaLink metaObj={collectionObj} variant="ui">
              {moreLink.customText || 'View all'}
            </MetaLink>
          }
        </div>,
    search,
  }
}

type FilterProp = {
  prop: string // without dash prefix
  allOptionLabel: string
  defaultValue?: string
  multi?: boolean
  reverse: boolean
}

type ConfigFilterField = {
  prop: string
  defaultValue: string
  multi: boolean
  allOption: {text: string; value: string}
  reverse: boolean
}

function configFilterFields(
  filterProps: FilterProp[],
): Array<[string, ConfigFilterField]> {
  return filterProps
    .filter(f => f.prop)
    .map(f => ({defaultValue: '', multi: false, ...f}))
    .map(({allOptionLabel, ...f}) => ({
      ...f,
      allOption: {text: allOptionLabel, value: ''},
    }))
    .map((f, i) => [`filter${i}`, f]) // caller will fromPairs
}
