import * as A from '../admin'
import {RedemptionBlockConfig, RedemptionBlockGlobalConfig} from './meta'

export const Admin: A.Admin<RedemptionBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global redemption configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)

export const GlobalAdmin: A.Admin<RedemptionBlockGlobalConfig> = () => (
  <A.Group>
    <A.Input label="Path to static page for redemptions" name="url" />
  </A.Group>
)
