import {AuthAgent} from 'quickstart/types'
import * as A from '../admin'
import {SignInBlockConfig, SignInBlockGlobalConfig} from './meta'

const authAgentOptions: {[k in AuthAgent]: string} = {
  tizra: 'Tizra (including SSO by customLoginUrl)',
  remote: 'remoteAuthenticationAgent',
}

export const Admin: A.Admin<SignInBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <A.Group title="Authentication">
      <A.Radio
        label="Override authentication agent"
        name="authAgent"
        options={A.options({
          '': 'Default',
          ...authAgentOptions,
        })}
        hint="Use this to override the global sign-in config only for this block"
      />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)

export const GlobalAdmin: A.Admin<SignInBlockGlobalConfig> = ({values}) => (
  <A.Group>
    <A.YesNo
      label="Preferred authentication flow"
      name="modal.enabled"
      yes="Modal dialog"
      no="Pages and blocks"
      hint={
        <span>
          For information about these options, see the Authentication section in
          the{' '}
          <a
            href="https://github.com/Tizra/Tizra-Customer-Tracker/wiki/Evergreen-guide#authentication"
            target="_blank"
            rel="noreferrer"
          >
            Evergreen Guide.
          </a>
        </span>
      }
    />
    <A.Radio
      label="Default authentication agent"
      name="authAgent"
      options={A.options(authAgentOptions)}
    />
    <A.Input label="Path to static page for login" name="signin.url" />
    <A.SubGroup title="Tizra authentication options">
      <A.CheckRight
        label="Enable password reset"
        name="passwordReset.enabled"
      />
      <A.Input
        label="Path for password reset"
        name="passwordReset.url"
        hint="This path is required by the system and cannot be changed."
        disabled
        hidden={!values.passwordReset?.enabled}
      />
      <A.CheckRight
        label="Enable user registration"
        name="registration.enabled"
      />
      <A.Input
        label="Path for user registration"
        name="registration.url"
        hint="This path is required by the system and cannot be changed."
        disabled
        hidden={!values.registration?.enabled}
      />
    </A.SubGroup>
  </A.Group>
)
