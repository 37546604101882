import * as A from '../admin'
import {SubnavBlockConfig} from './meta'

export const Admin: A.Admin<SubnavBlockConfig> = () => {
  return (
    <>
      <A.Group title="Content">
        <A.Input label="Heading" name="heading" />
      </A.Group>
      <A.Group title="Collections to show">
        <A.Taggers name="match" />
      </A.Group>
    </>
  )
}
