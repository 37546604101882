import * as B from '../block'
import {PostHeader} from './PostHeader'
import {PublicationHeader} from './PublicationHeader'
import {Admin} from './admin'
import {meta} from './meta'

export const HeaderBlock = B.blockWithInfo({...meta, Admin}, ({config}) =>
  config.mode === 'publication' ?
    <PublicationHeader {...config} />
  : <PostHeader {...config.post} />,
)
