import * as B from '../block'
import {Admin, GlobalAdmin} from './admin'
import {Cart} from './Cart'
import {Checkout} from './Checkout'
import {meta} from './meta'

export const CartBlock = B.blockWithInfo(
  {...meta, Admin, GlobalAdmin},
  ({config}) => {
    const {hash} = B.useLocation()
    return hash === '#checkout' ?
        <Checkout config={config} />
      : <Cart config={config} />
  },
)
