import {logger} from 'tizra'
import * as B from '../block'
import {CheckoutWizard} from './CheckoutWizard'
import {CartBlockConfig} from './meta'
import {OrderSummary} from './OrderSummary'

const log = logger('CartBlock/Checkout')

interface CheckoutProps {
  config: CartBlockConfig
}

export const Checkout = ({config}: CheckoutProps) => {
  const userData = B.useUserData()

  const {
    checkouts,
    defaultCheckout,
    checkoutName,
    setCheckoutName,
    checkout,
    firstIncomplete,
    submit,
  } = B.useCheckout()

  return (
    <B.Section>
      <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
        {userData === null || !checkouts ?
          <div />
        : <CheckoutWizard
            // Re-render from scratch whenever checkoutName or firstIncomplete
            // changes.
            key={`${checkoutName || '_pick'}:${firstIncomplete ?? -1}`}
            config={config}
            userData={userData}
            checkouts={checkouts}
            defaultCheckout={defaultCheckout}
            checkoutName={checkoutName}
            setCheckoutName={setCheckoutName}
            checkout={checkout}
            firstIncomplete={firstIncomplete}
            submit={submit}
          />
        }
        <OrderSummary config={config} />
      </B.Grid>
    </B.Section>
  )
}
