import {UseSearchReturn} from 'quickstart/hooks'
import {FC, Fragment, ReactNode, useMemo} from 'react'
import * as Final from 'react-final-form'
import {logger} from 'tizra'
import * as B from '../block'
import {BookshelfBlockConfig, BookshelfBlockSortProp} from './meta'

const log = logger('BookshelfControls')

interface BookshelfSortControlProps {
  config: BookshelfBlockConfig
  redemptionConfig: {url: string}
  search: UseSearchReturn
  sortProps: BookshelfBlockSortProp[]
}

interface ParsedValue {
  index: number
  name: string
  dir: 'ascending' | 'descending'
}

const parseValue: (v: string | null | undefined) => ParsedValue = v => {
  const match = v?.match(/^(\d+)-(.*)-(ascending|descending)$/)
  return match ?
      {
        index: parseInt(match[1]),
        name: match[2],
        dir: match[3] as ParsedValue['dir'],
      }
    : {
        index: 0,
        name: '',
        dir: 'ascending',
      }
}

const formatValue = ({index, name, dir}: ParsedValue) =>
  `${index}-${name}-${dir}`

const reverse = (dir: 'ascending' | 'descending') =>
  dir === 'ascending' ? 'descending' : 'ascending'

const DropdownSelect: FC<{
  children: ReactNode
  options: ReactNode[]
  onChange: (i: number) => void
}> = ({children, options, onChange}) => {
  const dropdown = B.DropdownMenu.useStore()
  return (
    <Fragment>
      <B.DropdownMenu.Button store={dropdown}>{children}</B.DropdownMenu.Button>
      <B.DropdownMenu store={dropdown} aria-label="Sort menu">
        {options.map((option, i) => (
          <B.DropdownMenu.Item
            key={i}
            onClick={() => {
              dropdown.hide()
              onChange(i)
            }}
          >
            {option}
          </B.DropdownMenu.Item>
        ))}
      </B.DropdownMenu>
    </Fragment>
  )
}

export const BookshelfControls = ({
  config,
  redemptionConfig,
  search,
  sortProps,
}: BookshelfSortControlProps) => {
  const sortLabels = useMemo(
    () => sortProps.map(({label}) => label),
    [sortProps],
  )
  return (
    <B.SearchForm instant search={search}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // TODO This value should match the space-after of the bookshelf block
          // heading.
          marginBottom: '1.875rem',
        }}
      >
        <Final.Field name="sort">
          {({input}) => {
            const {index, name, dir} = parseValue(input.value)
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <DropdownSelect
                  options={sortLabels}
                  onChange={(index: number) => {
                    const {name, defaultDirection: dir} = sortProps[index]
                    input.onChange(formatValue({index, name, dir}))
                  }}
                >
                  Sort: {sortLabels[index]}
                </DropdownSelect>
                <B.IconButton
                  icon={dir === 'descending' ? 'arrowDown' : 'arrowUp'}
                  onClick={() =>
                    input.onChange(
                      formatValue({index, name, dir: reverse(dir)}),
                    )
                  }
                />
              </div>
            )
          }}
        </Final.Field>
        {config.addContent.enabled && (
          <B.Button
            as={B.UniversalLink}
            variant="secondary"
            // @ts-expect-error Button as doesn't adjust props
            href={redemptionConfig.url}
          >
            Add content
          </B.Button>
        )}
      </div>
    </B.SearchForm>
  )
}
