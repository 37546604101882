import * as B from '../block'
import {useBookshelfConfig} from '../BookshelfBlock'
import {Admin, GlobalAdmin} from './admin'
import {meta} from './meta'

export const NavBlock = B.blockWithInfo(
  {
    ...meta,
    Admin,
    GlobalAdmin,
    dividers: false, // Nav component supplies its own
  },
  () => {
    const {
      quickSearchPlaceholder,
      includeCart,
      includeSignIn,
      nestedNavConfig,
    } = B.useGlobalBlockConfig(meta.name)
    const bookshelfConfig = useBookshelfConfig()
    const {logoUrl, logoLink} = B.useGlobalBlockConfig('theme')
    return (
      <B.Nav
        bookshelfConfig={bookshelfConfig}
        logoUrl={logoUrl}
        logoLink={logoLink}
        quickSearchPlaceholder={quickSearchPlaceholder}
        includeCart={includeCart}
        includeSignIn={includeSignIn}
        nestedNavConfig={nestedNavConfig}
      />
    )
  },
)
