import * as AK from '@ariakit/react'
import {useDebouncedCallback} from 'quickstart/hooks'
import {
  Key,
  ReactNode,
  startTransition,
  useEffect,
  useRef,
  useState,
} from 'react'
import {logger} from 'tizra'
import {Field, FieldPropsBase} from './Field'
import * as S from './styles'
import {tid} from 'quickstart/utils'

const log = logger('form/Combobox')

interface FormComboboxProps<Item>
  extends Omit<AK.FormControlProps<'input'>, 'onChange' | 'render' | 'value'> {
  itemToKey?: (item: Item | null, i: number) => Key
  itemToString?: (item: Item | null) => string
  placeholder?: string
  renderItem?: (item?: Item) => ReactNode
  search?: (
    input: string,
    selectionStart: number,
    selectionEnd: number,
  ) => Promise<Item[] | null>

  /**
   * This value of minChars is separate from the value in the Tizra search
   * config. This value controls whether the search callback is called, but the
   * autocompleter might choose to avoid actually calling the API until there
   * are additional chars, referring to autoComplete.minChars in
   * default-config.js
   */
  minChars?: number

  /**
   * Similar to minChars, this controls how the Search component debounces calls
   * to the search callback. If this is null or undefined, the callback will not
   * be debounced, though the callback (namely the autocompleter) might
   * implement its own internal debouncing.
   */
  wait?: number

  /**
   * Should the search submit when a value is selected from the dropdown?
   * This should be false (default) for advanced search, but true for quick
   * search and search results.
   */
  submitOnSelect?: boolean
}

const FormCombobox = <Item,>({
  name,
  itemToKey = (_, i) => i,
  itemToString = item => `${item}`,
  renderItem = () => <AK.ComboboxItemValue />,
  placeholder,
  search,
  minChars = 1,
  wait,
  submitOnSelect = false,
  ...props
}: FormComboboxProps<Item>) => {
  const inputRef = useRef<HTMLInputElement>(null)

  // This component is always controlled, meaning the current value lives in
  // form storage outside the component.
  const form = AK.useFormContext()!
  const value = form.useValue<string>(name) ?? ''

  // Keep results in state.
  const [results, setResults] = useState<Item[] | null>(null)

  // Update results when typing.
  const updateResults = useDebouncedCallback(
    async value => {
      if (!search || !value || value.length < minChars) {
        startTransition(() => setResults(null))
      } else {
        const data = await search(
          value,
          inputRef.current?.selectionStart ?? value.length,
          inputRef.current?.selectionEnd ?? value.length,
        )
        // If the search returns null, that means it's either not configured or
        // it was interrupted. Wait for the next to update results.
        if (!data) return
        // This check avoids breakage under test, where everything is
        // unmounted and the global window goes away, but this async function
        // hasn't finished running.
        if (typeof window === 'undefined') return
        startTransition(() => setResults(data))
      }
    },
    {wait},
    [inputRef, minChars, search, wait],
  )

  // Close combobox on submit, even if combobox remains focused.
  const submitting = AK.useStoreState(form, state => state.submitting)
  const store = AK.useComboboxStore()
  useEffect(() => {
    if (submitting) store.setOpen(false)
  }, [store, submitting])

  return (
    <AK.ComboboxProvider
      value={value}
      setValue={value => {
        form.setValue(name, value)
        updateResults(value)
      }}
      setSelectedValue={submitOnSelect ? form.submit : undefined}
      store={store}
    >
      <AK.FormControl
        name={name}
        render={
          <AK.Combobox
            placeholder={placeholder}
            render={<S.Input />}
            ref={inputRef}
          />
        }
        {...props}
      />
      <AK.ComboboxPopover sameWidth render={<S.PickerPopover />}>
        {!results ?
          null
        : !results.length ?
          <S.PickerItem>No results found</S.PickerItem>
        : results.map((item, i) => (
            <AK.ComboboxItem
              key={itemToKey(item, i)}
              value={itemToString(item)}
              render={<S.PickerItem {...tid(`item-${i}`)} />}
            >
              {renderItem(item)}
            </AK.ComboboxItem>
          ))
        }
      </AK.ComboboxPopover>
    </AK.ComboboxProvider>
  )
}

interface ComboboxFieldProps<Item>
  extends Omit<FieldPropsBase, 'type'>,
    Omit<FormComboboxProps<Item>, 'size'> {
  fieldType?: FieldPropsBase['type']
}

const ComboboxField = <Item = any,>({
  name,
  label,
  rightLabel,
  hint,
  size,
  fieldType = 'input',
  ...props
}: ComboboxFieldProps<Item>) => (
  <Field
    name={name}
    label={label}
    rightLabel={rightLabel}
    hint={hint}
    size={size}
    type={fieldType}
  >
    <FormCombobox name={name} {...props} />
  </Field>
)

export type {ComboboxFieldProps as ComboboxProps}

export const Combobox = Object.assign(ComboboxField, {Input: FormCombobox})
