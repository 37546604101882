import {MetaObject, logger} from 'tizra'
import * as B from '../block'
import {useOffers} from './Gain'
import {HeaderBlockConfig, migrateButtonToMarkdown} from './meta'

const log = logger('HeaderBlock')

type MessageProps = Pick<
  HeaderBlockConfig,
  'alignment' | 'callsToAction' | 'message' | 'v1'
> & {metaObj: MetaObject | undefined; pageId: string | undefined}

export const Message = (props: MessageProps) =>
  props.v1 ? <MessageV1 {...props} /> : <MessageV2 {...props} />

Message.displayName = 'HeaderBlock.Message'

function MessageV2({alignment, message}: MessageProps) {
  const html = B.useMarkdown(message)
  return !html ? null : (
      <B.Html variant="raw" html={html} style={{textAlign: alignment}} />
    )
}

MessageV2.displayName = 'HeaderBlock.MessageV2'

/* v1 had messages for three scenarios, whereas v1 consolidates into a single
 * message. Handle that as a pseudo migration here.
 * - has access
 * - no access, signed out
 * - no access, signed in
 *
 * Once the v2 config is saved in the admin, this pseudo-migration is no longer
 * used.
 */
function MessageV1({
  alignment,
  callsToAction,
  metaObj,
  pageId,
  v1: _v1,
}: MessageProps) {
  const v1 = _v1!
  const canAccess = B.useAccess({
    metaObj,
    views: ['page', 'sourceDownload'],
  })
  const userData = B.useUserData()
  const offers = useOffers({callsToAction, metaObj, pageId})

  let markdown: string | null

  if (!canAccess) {
    // API hasn't responded yet, wait for it.
    markdown = null
  } else if (Object.values(canAccess).some(Boolean)) {
    // User has access, doesn't matter if they're signed in or not.
    markdown = v1.callsToAction.canAccess.message.trim()
  } else if (!offers || userData === null) {
    // API hasn't responded yet, wait for it.
    markdown = null
  } else if (offers.length) {
    // There are offers on display. Don't spoil it by migrating the old
    // without-access messages and buttons.
    markdown = null
  } else if (userData) {
    // User is signed in without access.
    markdown =
      v1.callsToAction.noAccessSignedIn.message +
      '\n\n' +
      migrateButtonToMarkdown(v1.callsToAction.noAccessSignedIn.buttons[0])
  } else {
    // User is signed out without access.
    markdown =
      v1.callsToAction.noAccessSignedOut.message +
      '\n\n' +
      migrateButtonToMarkdown(v1.callsToAction.noAccessSignedOut.buttons[0])
  }

  const html = B.useMarkdown(markdown)
  return !html ? null : (
      <B.Html variant="raw" html={html} style={{textAlign: alignment}} />
    )
}

MessageV1.displayName = 'HeaderBlock.MessageV1'
