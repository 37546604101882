import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

export const TocBlock = B.blockWithInfo(
  {...meta, Admin},
  ({config: {heading, labelFreeContent, showPageNums, style}}) => {
    const metaObj = B.useMetaObj(true)
    const content =
      B.useMetaToc({
        metaObj,
        labelFreeContent,
        showPageNums,
        variant: style,
      }) || null

    return (
      <B.Scene>
        {content && <B.Section heading={heading}>{content}</B.Section>}
      </B.Scene>
    )
  },
)
