import * as A from '../admin'
import {FooterBlockGlobalConfig} from './meta'

export const Admin = () => (
  <p>
    Footer is configured globally in <A.NavOut tab="Design" subtab="nav" />.
  </p>
)

export const GlobalAdmin: A.Admin<FooterBlockGlobalConfig> = () => (
  <>
    <A.Group title="Links">
      <A.AdminField>
        <A.Hint>Freemarker supported in labels and hrefs.</A.Hint>
      </A.AdminField>
      <A.AdminField>
        <A.NavTree name="items" type="footer" />
      </A.AdminField>
    </A.Group>
    <A.Group title="Appearance">
      <A.Radio
        label="Alignment"
        name="alignment"
        options={A.options({
          left: 'Left aligned',
          center: 'Centered',
          right: 'Right aligned',
        })}
      />
    </A.Group>
    <A.Group>
      <A.Input label="Copyright" name="copyright" />
    </A.Group>
  </>
)
