import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

export const PostBlock = B.blockWithInfo({...meta, Admin}, ({config}) => {
  const hasContent = config.staticText.trim() ? true : null
  return (
    <B.Scene>
      {hasContent && (
        <B.Section>
          <B.StaticContent {...config} />
        </B.Section>
      )}
    </B.Scene>
  )
})
