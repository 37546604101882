import {Params, useUrlParamsMap, UseSearchReturn} from 'quickstart/hooks'
import * as R from 'rambdax'
import {useEffect, useRef} from 'react'
import {IS_STORYBOOK, SearchResultTypes} from 'tizra'
import {Form, FormProps, useFormStore} from './Form'

export interface SearchFormProps<T = SearchResultTypes>
  extends Omit<FormProps, 'store'> {
  search: UseSearchReturn<T>
}

/**
 * Search form that updates params and results on every change.
 */
export const InstantSearchForm = <T = SearchResultTypes,>({
  search: {params, update},
  ...props
}: SearchFormProps<T>) => {
  // Make the entire form a controlled input, by pushing every value change
  // directly into search.
  const form = useFormStore<Record<string, any>>({
    values: params,
    setValues: update,
  })
  return <Form store={form} {...props} />
}

/**
 * Wrapper for useFormStore() that pushes param updates back into form values.
 */
const useSearchForm = ({params}: {params: Params}) => {
  const form = useFormStore<Params>({defaultValues: params})

  // The form won't update automatically if defaultValues changes.
  // Push params back into form values here.
  const prevParamsRef = useRef(params)
  useEffect(() => {
    const prevParams = prevParamsRef.current
    const state = form.getState()
    const {values: formValues} = state
    // Only consider the intersection of form fields and params.
    const names = state.items
      .filter(item => item.type === 'field')
      .map(item => item.name)
      .filter(name => name in params)
    for (const name of names) {
      const v = params[name]
      const fv = formValues[name]
      const pv = prevParams[name]
      if (v !== pv && fv !== v && !R.equals(v, pv) && !R.equals(fv, v)) {
        form.setValue(name, v)
      }
    }
    prevParamsRef.current = params
  }, [form, params])

  return form
}

/**
 * Search form that navigates to search results on submit.
 */
export const QuickSearchForm = ({
  search: {params, config},
  relative = false,
  navigate = IS_STORYBOOK ?
    s => alert(s)
  : s => void (window.location.href = s),
  ...props
}: SearchFormProps & {
  relative?: boolean
  /**
   * Injectable so that tests can provide alternative implementations.
   */
  navigate?: (s: string) => void
}) => {
  const form = useSearchForm({params})
  const {parseParamsOut} = useUrlParamsMap({config})

  form.useSubmit(({values: {terms}}) => {
    const usp = parseParamsOut({...params, terms})
    const queryString = usp.toString()

    // https://github.com/Tizra/evergreen/issues/457
    const {pathname} = window.location
    const base =
      !relative ? '/'
      : /^[/][^/]+$/.test(pathname) ? `${pathname}/`
      : ''

    navigate(base + '~searchResults' + (queryString && '?') + queryString)
  })

  return <Form store={form} {...props} />
}

/**
 * Search form that updates params and results when submitted.
 */
export const SearchForm = <T = SearchResultTypes,>({
  search: {config, params, update},
  ...props
}: SearchFormProps<T>) => {
  const form = useSearchForm({params})

  form.useSubmit(({items, values}) => {
    // Only update the intersection of form fields and config fields.
    const params = items
      .filter(item => item.type === 'field')
      .map(item => item.name)
      .filter(name => name in config.fields)
      .reduce(
        (params, name) => {
          params[name] = values[name]
          return params
        },
        {} as typeof values,
      )
    update(params)
  })

  return <Form store={form} {...props} />
}
