import * as A from '../admin'
import {BrowseBlockConfig} from './meta'

export const Admin: A.Admin<BrowseBlockConfig> = () => {
  const tabs = A.useBrowseConfigTabs({
    include: {heading: true, filters: true},
  })
  return (
    <A.Tabs>
      {Object.entries(tabs).map(([key, {content, title}]) => (
        <A.Tab key={key} title={title}>
          {content}
        </A.Tab>
      ))}
    </A.Tabs>
  )
}
