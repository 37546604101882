import {
  NavLinkAccountProps,
  NavLinkBookshelfProps,
  NavLinkExpandoProps,
  NavLinkLinkProps,
  NavLinkMenuProps,
  NavLinkProps,
  NavLinkSeparatorProps,
} from 'quickstart/components/layout/NavLink'
import {useEffectCallback} from 'quickstart/hooks/useEffectCallback'
import * as R from 'rambdax'
import {useId, useMemo, useState} from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import * as Final from 'react-final-form'
import {logger} from 'tizra'
import * as A from '../../base'
import {
  AccountInput,
  BookshelfInput,
  ExpandoInput,
  LinkInput,
  MenuInput,
  SeparatorInput,
  Switcher,
} from './inputs'
import {isEmptyLink} from './util'

const log = logger('NavBlock/NavTree')

let _nextId = 1111

const nextId = () => (_nextId++).toString()

interface Tree {
  id: string
  props: NavLinkProps
  trees?: Tree[]
}

const inputComponents = {
  account: AccountInput,
  bookshelf: BookshelfInput,
  expando: ExpandoInput,
  link: LinkInput,
  menu: MenuInput,
  separator: SeparatorInput,
}

const splice = <T,>(orig: T[], i: number, n: number, ...add: T[]): T[] => [
  ...orig.slice(0, i),
  ...add,
  ...orig.slice(i + n),
]

function toTrees(items: NavLinkProps[]): Tree[] {
  const trees = items.map(props => ({
    id: nextId(),
    props,
    ...(props.type === 'menu' && {trees: toTrees(props.items || [])}),
  }))
  return trees
}

function ensureMenusEndWithEmptyLink(trees: Tree[]): Tree[] {
  return R.piped(
    trees,
    R.map(tree =>
      tree.trees ?
        {...tree, trees: ensureMenusEndWithEmptyLink(tree.trees)}
      : tree,
    ),
    trees => {
      const lastTree = R.last(trees)
      if (!lastTree || !isEmptyLink(lastTree.props)) {
        trees = trees.concat({id: nextId(), props: {type: 'link'}})
      }
      return trees
    },
  )
}

const propsToKeep = {
  account: ['label'],
  bookshelf: ['label'],
  expando: ['adminTag', 'metaTypes', 'sortProp', 'sortDirection'],
  link: ['label', 'href'],
  menu: ['label'], // items handled specially
  separator: [],
} satisfies {
  account: Array<keyof NavLinkAccountProps>
  bookshelf: Array<keyof NavLinkBookshelfProps>
  expando: Array<keyof NavLinkExpandoProps>
  link: Array<keyof NavLinkLinkProps>
  menu: Array<keyof NavLinkMenuProps>
  separator: Array<keyof NavLinkSeparatorProps>
}

function fromTrees(trees: Tree[]): NavLinkProps[] {
  return trees.map(tree => {
    const {type} = tree.props
    const props = {
      type,
      ...R.pick(propsToKeep[type], tree.props),
    } as NavLinkProps
    return props.type === 'menu' ?
        {...props, items: fromTrees(tree.trees || [])}
      : props
  })
}

interface IndexNode {
  tree: Tree
  index: number
  level: number
  parent: IndexNode | null
}

function indexTree(
  trees: Tree[],
  level = 0,
  parent: IndexNode | null = null,
): IndexNode[] {
  return trees.flatMap((tree, index) => {
    const indexNode = {tree, index, level, parent}
    return [
      indexNode,
      ...(tree.props.type === 'menu' ?
        indexTree(tree.trees || [], level + 1, indexNode)
      : []),
    ]
  })
}

const lensIdentity = R.lens(R.identity, R.identity)

const lensTree = (indexNode: IndexNode | null) => {
  const path: number[] = []
  for (; indexNode; indexNode = indexNode.parent) {
    path.unshift(indexNode.index)
  }
  return R.compose(
    lensIdentity,
    // @ts-expect-error
    ...path.map(i => R.compose(R.lensIndex(i), R.lensProp<Tree>('trees'))),
  ) as R.Lens<Tree[], Tree[]>
}

export const NavTree = ({
  name,
  type,
}: {
  name: string
  type: 'main' | 'profile' | 'footer'
}) => {
  const {value, onChange} = Final.useField<NavLinkProps[]>(name, {
    subscription: {value: true},
  }).input

  const [trees, setTrees] = useState(() =>
    ensureMenusEndWithEmptyLink(toTrees(value || [])),
  )

  const indexed = useMemo(() => indexTree(trees), [trees])

  const update = useEffectCallback(
    (_newTrees: typeof trees | ((oldTrees: typeof trees) => typeof trees)) => {
      const newTrees =
        typeof _newTrees === 'function' ? _newTrees(trees) : _newTrees
      setTrees(ensureMenusEndWithEmptyLink(newTrees))
      onChange(fromTrees(newTrees))
    },
  )

  const remove = useEffectCallback((i: number) => {
    const indexNode = indexed[i]
    update(
      R.over(
        lensTree(indexNode.parent),
        trees => splice(trees, indexNode.index, 1),
        trees,
      ),
    )
  })

  const edit = useEffectCallback((i: number, props: NavLinkProps) => {
    const indexNode = indexed[i]
    update(
      R.over(
        lensTree(indexNode.parent),
        trees =>
          splice(trees, indexNode.index, 1, {
            trees: [],
            ...indexNode.tree,
            props,
          }),
        trees,
      ),
    )
  })

  const onDragEnd = ({source, destination}: DropResult) => {
    if (!destination) return

    const sourceIndexNode = indexed[source.index]
    const remove = R.pipe(
      R.over(lensTree(sourceIndexNode.parent), trees =>
        splice(trees, sourceIndexNode.index, 1),
      ),
      log.pipe('onDragEnd after remove'),
    )

    // The destination index provided by react-beautiful-dnd assumes that the
    // source index has already been removed. This is normally convenient, but
    // our index-to-tree calculation works against the original tree, so we need
    // to adjust the destination index accordingly.
    const destIndex =
      destination.index >= source.index ?
        destination.index + 1
      : destination.index

    // The item being dragged will be inserted before destIndex. So if the
    // drag target is the bottom of the tree, destIndex will be out of bounds.
    // The remainder of the code here needs to handle that null destIndexNode
    // means end of list.
    const destIndexNode = destIndex < indexed.length ? indexed[destIndex] : null
    const insert = R.pipe(
      R.over(lensTree(destIndexNode?.parent ?? null), trees =>
        splice(
          trees,
          destIndexNode?.index ?? trees.length,
          0,
          sourceIndexNode.tree,
        ),
      ),
      log.pipe('onDragEnd after insert'),
    )

    // Don't allow moving a separator to the top level.
    if (
      sourceIndexNode.tree.props.type === 'separator' &&
      !destIndexNode?.parent
    ) {
      log.warn('prevented dragging separator to top level')
      return
    }

    // Don't allow moving a menu into itself.
    for (
      let destAncestor = destIndexNode;
      destAncestor;
      destAncestor = destAncestor.parent
    ) {
      if (destAncestor === sourceIndexNode) {
        log.warn('prevented dragging menu into itself')
        return
      }
    }

    // Since the remove/insert functions are each based on the original tree,
    // perform the operations in order from highest index to lowest so they
    // don't clash.
    update(
      R.piped(
        trees,
        log.pipe('onDragEnd before'),
        ...(source.index > destination.index ?
          ([remove, insert] as const)
        : ([insert, remove] as const)),
      ),
    )
  }

  const droppableId = useId()

  const canRemove = (i: number) => {
    if (i >= indexed.length) {
      log.error(
        `canRemove called with index out of bounds: ${i} >= ${indexed.length}`,
      )
      return false
    }
    const {parent, index} = indexed[i]
    return parent?.tree.trees ?
        index < parent.tree.trees.length - 1
      : i < indexed.length - 1
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {({droppableProps, innerRef, placeholder}) => (
          <A.Column {...droppableProps} ref={innerRef}>
            {indexed.map(({tree, level, parent}, i) => (
              <Draggable key={tree.id} draggableId={tree.id} index={i}>
                {({draggableProps, dragHandleProps, innerRef}) => {
                  const Input = inputComponents[tree.props.type]
                  return (
                    <A.Row
                      // alignItems start instead of center because ExpandoInput
                      // has multiple rows.
                      alignItems="start"
                      {...draggableProps}
                      {...dragHandleProps}
                      ref={innerRef}
                    >
                      {R.times(
                        i => (
                          <A.Box key={`indent-${i}]`} opacity={0}>
                            <A.Icon.Right />
                          </A.Box>
                        ),
                        level,
                      )}
                      <A.Box
                        // Ideally we'd use alignSelf="center" here, but the
                        // multi-row ExpandoInput messes that up.
                        pt="3px"
                      >
                        <A.Icon.DragHandle />
                      </A.Box>
                      <A.Box>
                        <Switcher
                          options={
                            type === 'footer' ? ['link']
                            : type === 'profile' ?
                              [
                                'account',
                                'bookshelf',
                                'link',
                                'menu',
                                'separator',
                              ]
                            : parent ?
                              ['expando', 'link', 'menu', 'separator']
                            : ['link', 'menu']
                          }
                          value={tree.props.type}
                          onChange={(type: NavLinkProps['type']) =>
                            edit(i, {
                              // This cheats by changing only the type and leaving
                              // the rest as-is. That makes it easy to toggle back
                              // if you make a mistake, because the data is still
                              // in the temporary tree. The extra data will be
                              // dropped by fromTrees() when writing back to global
                              // config.
                              ...tree.props,
                              type,
                              // Special fixups for account and bookshelf.
                              ...(type === 'account' &&
                                !(tree.props as any).label && {
                                  label: 'My Account',
                                }),
                              ...(type === 'bookshelf' &&
                                !(tree.props as any).label && {label: 'AUTO'}),
                            } as NavLinkProps)
                          }
                          onRemove={canRemove(i) ? () => remove(i) : undefined}
                        />
                      </A.Box>
                      <Input
                        props={tree.props}
                        update={props => edit(i, props)}
                      />
                    </A.Row>
                  )
                }}
              </Draggable>
            ))}
            {placeholder}
          </A.Column>
        )}
      </Droppable>
    </DragDropContext>
  )
}
