import * as A from '../admin'
import {SearchWithinBlockConfig, modes} from './meta'

export const Admin: A.Admin<SearchWithinBlockConfig> = ({values}) => (
  <A.Group title="Content">
    <A.Input label="Heading" name="heading" />
    <A.Input label="Placeholder" name="placeholder" />
    <A.Select label="Search context" name="mode" options={A.options(modes)} />
    <A.Input
      label="Specific Tizra ID to search"
      name="specificId"
      hidden={values.mode !== 'specific'}
    />
  </A.Group>
)
