import {ComponentProps} from 'react'
import {MetaObject, logger} from 'tizra'
import * as B from '../block'
import {Exercise, useActions} from './Exercise'
import {Gain, useOffers} from './Gain'
import {HeaderBlockConfig} from './meta'

const log = logger('HeaderBlock')

const ActionStack = (props: ComponentProps<typeof B.Stack>) => (
  <B.Stack spacing="xxl" wrapChildren={false} {...props} />
)

type ActionsProps = Pick<
  HeaderBlockConfig,
  'alignment' | 'callsToAction' | 'v1'
> & {
  // These are required so that caller is required to pass props even if they
  // are undefined.
  metaObj: MetaObject | undefined
  pageId: string | undefined
}

export const Actions = ({
  alignment,
  callsToAction,
  metaObj,
  pageId,
  v1,
}: ActionsProps) => {
  const offers = useOffers({callsToAction, metaObj, pageId})
  const actions = useActions({callsToAction, metaObj, v1})

  // Wait for API calls to complete before rendering anything, so it doesn't
  // flip flop.
  if (!actions || !offers) {
    return null
  }

  // Both API calls complete, but nothing to show.
  if (actions.length === 0 && offers.length === 0) {
    return null
  }

  return (
    <ActionStack>
      <Exercise actions={actions} alignment={alignment} />
      <Gain
        alignment={alignment}
        asModal={!!actions.length}
        metaObj={metaObj}
        offers={offers}
        pageId={pageId}
      />
    </ActionStack>
  )
}

Actions.displayName = 'HeaderBlock.Actions'
