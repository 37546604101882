import {logger} from 'tizra'
import * as B from '../block'
import {Admin} from './admin'
import {gaps} from 'quickstart/theme'
import {meta} from './meta'

const log = logger('BrowseBlock')

export const BrowseBlock = B.blockWithInfo(
  {...meta, Admin, ssrEnabled: false},
  ({config: {heading, moreLink, ...config}}) => {
    if (heading && !moreLink.customText) {
      moreLink = {...moreLink, customText: `View all ${heading}`}
    }
    const {background, foreground, ...browseProps} = B.useBrowseProps({
      ...config,
      moreLink,
    })
    const {filters, gallery, footer} = B.useBrowse(browseProps)

    if (!filters && !gallery) {
      return null
    }

    const style =
      browseProps.display === 'digests' ?
        {background, color: foreground}
      : undefined

    const sectionHeading = !!heading && (
      <B.Section.Heading>{heading}</B.Section.Heading>
    )

    return (
      <B.Section bleed style={style}>
        <B.Container>
          {!filters && sectionHeading}
          <B.Stack spacing="xl">
            {filters && sectionHeading}
            {filters}
            <B.Stack
              spacing={config.display === 'cards' ? '40px' : gaps.sideNav}
              wrapChildren={false}
            >
              {gallery}
              {footer}
            </B.Stack>
          </B.Stack>
        </B.Container>
      </B.Section>
    )
  },
)
