import * as A from '../admin'
import {StaticPageBlockConfig} from './meta'

export const Admin: A.Admin<StaticPageBlockConfig> = () => {
  const containerOptions = A.useOptions<StaticPageBlockConfig['container']>(
    A.containerOptions,
  )
  return (
    <A.Tabs>
      <A.Tab title="Presentation">
        <A.Radio
          label="Text container"
          name="container"
          options={containerOptions}
        />
      </A.Tab>
    </A.Tabs>
  )
}
