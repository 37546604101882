import * as A from '../admin'
import {ResetPasswordBlockConfig} from './meta'

export const Admin: A.Admin<ResetPasswordBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)
