import {useNavigate} from 'react-router-dom'
import {logger} from 'tizra'
import * as B from '../block'
import {CartItem} from './CartItem'
import {CartBlockConfig} from './meta'
import * as S from './styles'

const log = logger('CartBlock/OrderSummary')

interface OrderSummaryProps {
  config: CartBlockConfig
}

export const OrderSummary = (_props: OrderSummaryProps) => {
  const navigate = useNavigate()
  const {data: cart} = B.useApi.cart()

  if (!cart) {
    return null
  }

  const subtotal = cart.reduce(
    (sum: number, {offer: {price}}: any) => sum + price,
    0,
  )

  const currency =
    (cart[0] &&
      log.assert(
        cart[0].offer.currencyInfo,
        'missing currency-info in cart API response',
      )) ||
    'USD'

  return (
    <B.Stack spacing="xxl">
      <S.BorderedStack spacing="xxl">
        <B.LeftRight>
          <B.Text variant="h5">Order Summary</B.Text>
          <B.Text variant="textMd">
            <B.Link onClick={() => navigate('#cart')}>Edit bag</B.Link>
          </B.Text>
        </B.LeftRight>
        <B.Stack spacing="xs" divided>
          <B.Text>{cart?.length} items</B.Text>
          <B.Stack>
            {cart?.map((x: any, i: number) => (
              <CartItem {...x} variant="checkout" key={i} />
            ))}
          </B.Stack>
        </B.Stack>
      </S.BorderedStack>
      <B.Stack capped>
        <B.LeftRight>
          <B.Text variant="h5" as="div">
            Subtotal (USD)
          </B.Text>
          <B.Text variant="h5" as="div">
            <B.Currency amount={subtotal} currency={currency} />
          </B.Text>
        </B.LeftRight>
      </B.Stack>
    </B.Stack>
  )
}
