import * as A from '../admin'
import {RegisterUserBlockConfig} from './meta'

export const Admin: A.Admin<RegisterUserBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)
