import * as B from '../block'
import {Admin, GlobalAdmin} from './admin'
import {
  BaseGlobalStyle,
  CssModulesGlobalStyle,
  LinariaGlobalStyle,
  StyledComponentsGlobalStyle,
} from './globalStyles'
import {meta} from './meta'

export const ThemeBlock = B.blockWithInfo(
  {...meta, Admin, GlobalAdmin, wrapperDiv: false},
  () => (
    <>
      <BaseGlobalStyle />
      <CssModulesGlobalStyle />
      <LinariaGlobalStyle />
      <StyledComponentsGlobalStyle />
    </>
  ),
)
