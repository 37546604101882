import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

export const MarkdownBlock = B.blockWithInfo(
  {...meta, Admin},
  ({config: {container, heading, message, background, justification}}) => {
    const html = B.useMarkdown(message) || ''
    const content =
      message.trim() && html.trim() ?
        <B.TextContainer variant={container} justify={justification}>
          {heading && <B.Section.Heading>{heading}</B.Section.Heading>}
          <B.Html as="div" html={html} variant="dangerous" reset />
        </B.TextContainer>
      : null

    return (
      <B.Scene>
        {content && <B.Background content={content} background={background} />}
      </B.Scene>
    )
  },
)
