import {useAdminApi} from 'quickstart/hooks/useApi'
import {useMemo} from 'react'
import {AdminMetaObject} from 'tizra'
import * as A from '../admin'
import {ImageBlockConfig} from './meta'

export const Admin: A.Admin<ImageBlockConfig> = ({values}) => {
  const {data} = useAdminApi.list({metaType: ['Image']})

  const {images, imageOptions} = useMemo(() => {
    const images = (((data as any)?.result || []) as AdminMetaObject[]).filter(
      img => img.publishedStaging,
    )
    const imageOptions = A.makeOptions<AdminMetaObject>({
      labelKey: x => `${x.name} (${x.tizraId})`,
      valueKey: 'tizraId',
    })(images)
    return {images, imageOptions}
  }, [data])

  const {hint} = useMemo(() => {
    const chosenImage = images.find(img => img.tizraId === values.tizraId)
    const hint =
      !chosenImage ? undefined
      : !chosenImage.publishedLive ?
        'Warning: This image is not published to your live site!'
      : chosenImage.hasUnpublishedChanges ?
        'Warning: This image has unpublished changes.'
      : undefined
    return {hint}
  }, [images, values.tizraId])

  return (
    <>
      <A.Group title="Content">
        <A.Select
          label="Image"
          name="tizraId"
          noneOption="(custom URL)"
          options={imageOptions}
          hint={hint}
        />
        <A.Input
          label="Custom image URL"
          name="imageUrl"
          long
          hidden={!!values.tizraId}
        />
        <A.Input
          label="Alt text"
          name="altText"
          long
          hidden={!!values.tizraId}
        />
        <A.Input
          label="Link to"
          name="linkUrl"
          hint="Destination if image is clicked."
          long
        />
      </A.Group>
      <A.Group title="Presentation">
        <A.Radio
          label="Width"
          name="width"
          options={A.options({
            bleed: 'Full-bleed',
            contain: 'Fit in container',
          })}
        />
      </A.Group>
    </>
  )
}
