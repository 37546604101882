import {useCallback, useEffect, useMemo} from 'react'
import {IS_STORYBOOK, logger} from 'tizra'
import * as B from '../block'
import {Advanced} from './Advanced'
import {Results} from './Results'
import {Admin, GlobalAdmin} from './admin'
import {meta} from './meta'
import {TILDE_ADVANCED, TILDE_RESULTS, useSearchRouter} from './useSearchRouter'

const log = logger('SearchBlock')

const PREFIX = 'searchParam-'

export const SearchBlock = B.blockWithInfo(
  {
    ...meta,
    Admin,
    GlobalAdmin,
    ssrEnabled: false,
  },
  () => {
    const {showPageThumbnails} = B.useGlobalBlockConfig('search')
    const searchConfig = B.useGlobalSearchConfig()

    const {isRoutedSearch, onAdvancedSearch, switchToAdvanced} =
      useSearchRouter()

    const {urlParams, setUrlParams: _setUrlParams} = B.useUrlParams({
      config: searchConfig.config,
      prefix: PREFIX,
    })

    // TODO: replace this special setUrlParams with ariakit-based
    // AdvancedSearchForm that handles submit by navigating, rather than every
    // time params change.
    const setUrlParams = useCallback<typeof _setUrlParams>(
      (params, options) =>
        _setUrlParams(params, {
          location: loc =>
            // HACK: don't navigate when useSearch called setUrlParams the first
            // time to push extraParams into urlParams.
            options?.method === 'replace' ?
              loc
            : {
                ...loc,
                pathname: loc.pathname?.replace(
                  `/${TILDE_ADVANCED}`,
                  `/${TILDE_RESULTS}`,
                ),
              },
          ...options,
        }),
      [_setUrlParams],
    )

    const boostable = B.useHack('boost')

    const search = B.useSearch(searchConfig, {
      boostable,
      enabled: !onAdvancedSearch,
      snippet: true,
      ...(isRoutedSearch && {
        urlParams,
        setUrlParams,
      }),
    })

    // Server sets title to "autotest: Search Results" or "autotest Advanced
    // Search" (yes, one has colon and the other doesn't, so we repair to always
    // include colon). Append the current search terms.
    const siteName = useMemo(() => {
      const siteName =
        typeof document === 'undefined' ? undefined : (
          document.title.match(
            /^(.*?):*\s+(search result|advanced search).*$/i,
          )?.[1]
        )
      // In storybook, document.title is "blocks-search--site-search"
      // so it won't match above.
      return siteName || (IS_STORYBOOK && 'storybook') || ''
    }, [])
    useEffect(() => {
      if (siteName) {
        const pageName = onAdvancedSearch ? 'Advanced Search' : 'Search Results'
        const forTerms =
          search.params.terms ? ` for ‘${search.params.terms}’` : ''
        document.title = `${siteName}: ${pageName}${forTerms}`
      }
      // This will only track virtual page views when we switch to/from advanced
      // search, or when terms change. I think that's probably good.
      try {
        window.tizra.trackPageview()
      } catch (e) {
        log.error('failed trackPageview', e)
      }
    }, [onAdvancedSearch, siteName, search.params.terms])

    return onAdvancedSearch ?
        <Advanced search={search} />
      : <Results
          search={search}
          showPageThumbnails={showPageThumbnails}
          switchToAdvanced={switchToAdvanced}
        />
  },
)
