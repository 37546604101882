import * as A from '../admin'
import {CartBlockConfig, CartBlockGlobalConfig} from './meta'

export const Admin: A.Admin<CartBlockConfig> = () => (
  <A.Tabs>
    <A.Tab title="Empty cart">
      <A.MarkdownEditor
        name="emptyMessage"
        label="Message for empty cart"
        hint="Leave empty for default"
      />
    </A.Tab>
    <A.Tab title="Order summary">
      <A.Textarea
        name="summaryMessage"
        label="Custom message for order summary"
        hint="Default is no message"
      />
    </A.Tab>
  </A.Tabs>
)

export const GlobalAdmin: A.Admin<CartBlockGlobalConfig> = () => (
  <A.Group>
    <A.Input label="Path to static page for cart" name="url" />
  </A.Group>
)
