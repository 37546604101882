import {IconButton} from 'quickstart/components/controls/IconButton'
import * as F from 'quickstart/components/form'
import {
  Params,
  useGlobalSearchConfig,
  useSearch,
  UseSearchReturn,
} from 'quickstart/hooks'
import {ComponentProps, ReactNode} from 'react'
import {logger} from 'tizra'

const log = logger('QuickSearch')

interface QuickSearchProps
  extends Omit<
    ComponentProps<typeof F.QuickSearchForm>,
    'children' | 'search'
  > {
  button?: boolean
  placeholder?: string
  extraParams?: Params
  children?: (renderProps: QuickSearchRenderProps) => ReactNode
}

interface QuickSearchRenderProps {
  search: UseSearchReturn
}

const Button = (props: ComponentProps<typeof F.Submit>) => (
  <F.Submit
    render={
      <IconButton
        icon="search"
        style={{
          // Nav has height=48px py=10px which yields 28px internal height.
          // Input size=md is 3rem equals 48px, so this calculation would yield
          // the same result. However it also works for size=lg 3.5rem which is
          // an additional 8px.
          height: 'calc(100% - 20px)',
        }}
      />
    }
    {...props}
  />
)

const Input = (props: ComponentProps<typeof F.TermsInput>) => (
  <F.TermsInput {...props} />
)

const _QuickSearch = ({
  button = true,
  placeholder,
  extraParams,
  children,
  ...props
}: QuickSearchProps) => {
  const searchConfig = useGlobalSearchConfig({mode: 'quick'})
  const search = useSearch(searchConfig, {enabled: false, extraParams})

  children ||= () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr min-content',
        alignItems: 'center',
      }}
    >
      <Input placeholder={placeholder} search={search} />
      {button && <Button />}
    </div>
  )

  return (
    <F.QuickSearchForm search={search} {...props}>
      {children({search})}
    </F.QuickSearchForm>
  )
}

export const QuickSearch = Object.assign(_QuickSearch, {Button, Input})
