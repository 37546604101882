import * as A from '../admin'
import {HeadlineBlockConfig} from './meta'

export const Admin: A.Admin<HeadlineBlockConfig> = () => {
  const leftTabs = A.useBrowseConfigTabs({
    name: 'left',
    include: {maxItems: false},
    constrain: {display: ['headline']},
  })
  const rightTabs = A.useBrowseConfigTabs({
    name: 'right',
    constrain: {display: ['titles']},
  })
  return (
    <A.Tabs>
      {Object.entries(leftTabs).map(([key, left]) => {
        const right = rightTabs[key as keyof typeof leftTabs]
        return (
          <A.Tab key={key} title={left.title}>
            <A.Stack row width="100%">
              <div style={{flexGrow: 1}}>
                <A.P style={{fontWeight: 'bold'}}>Left side</A.P>
                {left.content}
              </div>
              <A.Vr />
              <div style={{flexGrow: 1}}>
                <A.P style={{fontWeight: 'bold'}}>Right side</A.P>
                {right?.content}
              </div>
            </A.Stack>
          </A.Tab>
        )
      })}
    </A.Tabs>
  )
}
