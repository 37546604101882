import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

export const ResetPasswordBlock = B.blockWithInfo(
  {...meta, Admin},
  ({config}) => {
    const authConfig = B.useAuthConfig({authAgent: 'tizra'})
    return (
      <B.Section>
        <B.Auth
          {...authConfig}
          {...config}
          modal={false}
          view="resetPassword"
        />
      </B.Section>
    )
  },
)
