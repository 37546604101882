import * as A from '../admin'
import {MarkdownBlockConfig} from './meta'

export const Admin: A.Admin<MarkdownBlockConfig> = () => {
  const tabs = A.useBackgroundConfigTabs({name: 'background'})
  const containerOptions = A.useOptions<MarkdownBlockConfig['container']>(
    A.containerOptions,
  )
  const justificationOptions = A.useOptions<
    MarkdownBlockConfig['justification']
  >(A.justificationOptions)
  return (
    <A.Tabs>
      <A.Tab title="Content">
        <A.Input label="Heading" name="heading" />
        <A.MarkdownEditor label="Custom text" name="message" reset />
      </A.Tab>
      <A.Tab title="Presentation">
        <A.SubTabs>
          <A.SubTab title="Background">
            {Object.entries(tabs.background).map(([k, props]) => (
              <A.SubGroup key={k} {...props} />
            ))}
          </A.SubTab>
          <A.SubTab title="Foreground">
            {Object.entries(tabs.foreground).map(
              ([k, {children, ...props}]) => (
                <A.SubGroup key={k} {...props}>
                  {k === 'layout' && (
                    <A.Radio
                      label="Text container"
                      name="container"
                      options={containerOptions}
                    />
                  )}
                  {children}
                  {k === 'layout' && (
                    <A.Radio
                      label="Text justification"
                      name="justification"
                      options={justificationOptions}
                    />
                  )}
                </A.SubGroup>
              ),
            )}
          </A.SubTab>
        </A.SubTabs>
      </A.Tab>
    </A.Tabs>
  )
}
