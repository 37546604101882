import {CURRENT_OBJ_VALUE} from 'quickstart/hooks/useWithinField'
import * as B from '../block'
import {Admin} from './admin'
import {meta} from './meta'

export const SearchWithinBlock = B.blockWithInfo(
  {...meta, Admin},
  ({config: {heading, mode, placeholder, specificId}}) => (
    <B.Section>
      <B.TextContainer
        variant="strict"
        style={{marginLeft: 'auto', marginRight: 'auto'}}
      >
        {heading && (
          <B.Section.Heading variant="sectionHeadTight">
            {heading}
          </B.Section.Heading>
        )}
        <B.QuickSearch
          button={false}
          placeholder={placeholder}
          extraParams={
            mode === 'current' ? {within: CURRENT_OBJ_VALUE}
            : mode === 'specific' && specificId ?
              {within: specificId}
            : undefined
          }
          relative={mode === 'current'}
        />
      </B.TextContainer>
    </B.Section>
  ),
)
