import * as A from '../admin'
import {NavBlockGlobalConfig} from './meta'

export const Admin = () => (
  <p>
    Nav is configured globally in <A.NavOut tab="Design" subtab="nav" />.
  </p>
)

export const GlobalAdmin: A.Admin<NavBlockGlobalConfig> = ({values}) => {
  const hack = A.useHack('nestedNavUi')
  return (
    <>
      <A.Group>
        <A.CheckRight label="Include sign in" name="includeSignIn" />
        <A.CheckRight label="Include shopping cart" name="includeCart" />
      </A.Group>
      {!hack ?
        <A.Group title="Nested Nav JSON">
          <A.Textarea label="Config JSON" name="nestedNavConfigJson" />
        </A.Group>
      : <>
          <A.Group title="Navigation tree">
            <A.AdminField>
              <A.Hint>Freemarker supported in labels and hrefs.</A.Hint>
            </A.AdminField>
            <A.AdminField>
              <A.NavTree name="nestedNavConfig.items" type="main" />
            </A.AdminField>
          </A.Group>
          {values.includeSignIn && (
            <A.Group title="Profile menu">
              <A.AdminField>
                <A.Hint>Only displays when signed in.</A.Hint>
              </A.AdminField>
              <A.AdminField>
                <A.NavTree name="nestedNavConfig.profileItems" type="profile" />
              </A.AdminField>
            </A.Group>
          )}
        </>
      }
      <A.Group title="Quick search">
        <A.Input
          label="Custom placeholder text"
          name="quickSearchPlaceholder"
        />
      </A.Group>
    </>
  )
}
